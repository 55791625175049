#video-background {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: 0px;
  z-index: -1000;
  object-fit: cover;
  top:0;
}

.workersHive_1 {
  font-size: 32px !important;
  font-weight: 700 !important;
  letter-spacing: 0px !important;
  margin-bottom: 18px !important;
  margin-top: 15px !important;
}

.workersHive_2 {
  font-size: 28px !important;
  font-weight: 700 !important;
  letter-spacing: 0px !important;
  margin-bottom: 18px !important;
  margin-top: 15px !important;
}

.navbar-default-extra {
  box-shadow: -1px -6px 20px 0px #c5c3c3;

  .navbar-nav > li > a {
    color: #131834;
  }

}


.hive{
  color: #EAB308;
}
.workers {
  color: #ffffff;
}
.workers_2 {
  color: #131834 !important;
}

.title2{
  color: #eab308;
  font-family: Georgia, Times, Times New Roman, serif;
  font-style: italic;
  font-weight: 400;
}

.roundBtn{
  border-radius: 26px !important;
  text-transform: capitalize;
}

.titlefont{
  font-family: "ui-rounded";
}

.foodTrustedTitle{
  color: #fff;
  text-align: center !important;
  margin: 0px 0px 50px 0px !important;
}

.foodBeverage{
  background-color: #000 !important;
  h1, h2, h3, h4, h5, h6{
    color: #fff;
  }
}
.col-sm-3{
  img{
    width: 50px;
  }
}

.image-container-trusted {
  display: inline-block;
}

.image-trusted {
  width: 25%;
  height: auto;
  aspect-ratio: 3 / 2;
  object-fit: contain;
  // mix-blend-mode: color-burn;
}



.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.image-container {
  position: relative;
  margin: 10px;
}

.image {
  width: 450px;
  transition: transform 0.3s ease;
  height: auto;
  aspect-ratio: 3 / 2;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.image-container:hover .image {
  transform: scale(0.9); /* Enlarge image on hover */
}

.image-container:hover .overlay {
  opacity: 1; /* Show overlay on hover */
}

.image-container:hover .text {
  opacity: 1; /* Show text on hover */
}

.enlarged-image-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: none;
}

.enlarged-image {
  max-width: 90vw;
  max-height: 90vh;
}

//Businesses

.BusinessesSection{

  .sectionSpace{
    background-color: #f3f3f3;
    border-radius: 9px;
    padding: 20px 42px;
    margin-bottom: 10px;
  }
  .sectionTitle{
    font-weight: 600;
  }

  .ImagesBusinesses{
    width: 40px;
  }
  h4{
    font-size: 12px;
  }
}


// FAQ
.faq{
  .section-header{
    margin-bottom:2px;
  }

  .accordion {
    margin-top: 40px;
    .card {
      border: none;
      h2 {
        margin: 0px;
        background: url(https://cdn0.iconfinder.com/data/icons/entypo/91/arrow56-512.png) no-repeat calc(100% - 10px) center;
      background-size: 20px;
        cursor: pointer;
        font-size: 18px;
        &.collapsed {
              background-image: url(https://cdn0.iconfinder.com/data/icons/arrows-android-l-lollipop-icon-pack/24/expand2-256.png);
        }
      }
      &-body {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .card-head{
      background-color: #f3f3f3;
      padding: 14px;
      margin-bottom: 10px;
    }
    .card-body{
      padding: 2px 25px;
      h5{
        font-weight: bold !important;
      }
      p{
        text-align: left;
      }
      li{
        color: #131834;
      }
    }
  }

}
